module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":690,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#013B28"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Events Management - Heartfulness","short_name":"Events Management - Heartfulness","description":"Events Management is a meditation app that helps with relaxation, meditation, rejuvenation, introspection and deep reflection.","start_url":"/","background_color":"#9B7E29","theme_color":"#013B28","display":"standalone","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"22d253bbf3905840fe2ee791fd2e6ee1"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-plugin-hfn-profile/gatsby-browser.js'),
      options: {"plugins":[],"pathToRootReducerModule":"./src/state/appReducer","firebaseAuthConfig":{"apiKey":"AIzaSyC_D--AX_STmQi4Ew6zbpKNFxkiCQpevHE","authDomain":"auth.heartfulness.org","projectId":"unifiedplatform"},"firebaseStoreConfig":{"apiKey":"AIzaSyDc3tJVUux7gYvis_ezIrwBgZ_WjZijaxU","authDomain":"hfn-registration.firebaseapp.com","databaseURL":"https://hfn-registration.firebaseio.com","projectId":"hfn-registration","storageBucket":"hfn-registration.appspot.com","messagingSenderId":"546875419081","appId":"1:546875419081:web:d89d88a740b17bbc"},"splashScreen":"loading..."},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["gotham"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-61002286-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
